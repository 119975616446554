import Link from 'next/link';
import { AppLayout } from 'src/components';

import {
  ClipboardListIcon,
  PhoneIcon,
  SearchIcon,
  TagIcon,
  HomeIcon,
} from '@heroicons/react/outline';
import { ChevronRightIcon } from '@heroicons/react/solid';

export const links = [
  {
    title: 'Home',
    href: '/',
    description: 'Go back to the home page',
    icon: HomeIcon,
  },
  {
    title: 'Contact Us',
    href: '/contact',
    description: 'Find out how to contact the Swapalease team',
    icon: PhoneIcon,
  },
  {
    title: 'Browse Leases',
    href: '/search',
    description: 'Find your next lease',
    icon: SearchIcon,
  },
  {
    title: 'List Your Lease',
    href: '/create',
    description: 'Check your eligibility and create a listing for your lease',
    icon: TagIcon,
  },
  {
    title: 'How it Works',
    href: '/learn',
    description:
      'Learn the ins and outs of lease transfers and how to avoid the pitfalls of exiting your lease',
    icon: ClipboardListIcon,
  },
];

function Custom404(): JSX.Element {
  return (
    <AppLayout>
      <div className="max-w-7xl w-full mx-auto px-4 sm:px-6 lg:px-8">
        <div className="max-w-xl mx-auto py-16 sm:py-24">
          <div className="text-center">
            <p className="text-sm font-semibold text-primary uppercase tracking-wide">404</p>
            <h1 className="mt-2 text-4xl font-extrabold text-gray-900 tracking-tight sm:text-5xl">
              This page does not exist.
            </h1>
            <p className="mt-2 text-lg text-gray-500">
              The page you are looking for could not be found.
            </p>
          </div>
          <div className="mt-12">
            <h2 className="text-sm font-semibold text-gray-500 tracking-wide uppercase">
              Popular pages
            </h2>
            <ul
              role="list"
              className="mt-4 border-t border-b border-gray-200 divide-y divide-gray-200"
            >
              {links.map((link, linkIdx) => (
                <li key={linkIdx} className="relative py-6 flex items-start space-x-4">
                  <div className="flex-shrink-0">
                    <span className="flex items-center justify-center h-12 w-12 rounded-lg bg-lighter-blue">
                      <link.icon className="h-6 w-6 text-primary" aria-hidden="true" />
                    </span>
                  </div>
                  <div className="min-w-0 flex-1">
                    <h3 className="text-base font-medium text-gray-900">
                      <span className="rounded-sm focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-light-blue">
                        <Link href={link.href || '#'}>
                          <a className="focus:outline-none">
                            <span className="absolute inset-0" aria-hidden="true" />
                            {link.title}
                          </a>
                        </Link>
                      </span>
                    </h3>
                    <p className="text-base text-gray-500">{link.description}</p>
                  </div>
                  <div className="flex-shrink-0 self-center">
                    <ChevronRightIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                  </div>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>
    </AppLayout>
  );
}

export default Custom404;
